// TeamMembers.js
import React from 'react';

const TeamMembers = () => {
  const teamMembers = [
    {
      name: 'Romain NERON',
      role: 'Gérant',
      description: 'Diagnostiqueur immobilier depuis plus de 10 ans, Romain est le fondateur de la société AGN Diagnostics. Il est également le gérant de la société.',
      phone: '06 29 75 43 49',
      email: 'agndiagnostics@hotmail.fr',
      image: '/static/romain_neron_agn_diagnostics_allier_vichy_abrest_saint-yorre_cusset.png', // Ajoutez le chemin de l'image
    },
    {
      name: 'Hyacinthe THOMAS',
      role: 'Diagnostiqueuse Immobilier',
      description: 'Diagnostiqueuse immobilier depuis bientôt 5 ans chez AGN Diagnostics, Hyacinthe est une experte dans son domaine.',
      phone: '06 19 16 14 50',
      email: 'agndiagnostics@hotmail.com',
      image: '/static/hyacinthe_thomas_agn_diagnostics_allier_vichy_abrest_saint-yorre_cusset.png', // Ajoutez le chemin de l'image
    },
    {
      name: 'Sarah LAURENT',
      role: 'Diagnostiqueuse Immobilier',
      description: 'Diagnostiqueuse immobilier depuis 1 an chez AGN Diagnostics, Sarah est déterminée et professionnelle.',
      phone: '06 65 26 24 48',
      email: 'agndiagnostics1@hotmail.fr',
      image: '/static/hidden.png', // Ajoutez le chemin de l'image
    },
  ];

  return (
    <section className="container teamcontainer mt-5">
      <h2 className="text-center mb-4">Notre équipe</h2>
      {teamMembers.map((member, index) => (
        <div key={index} className={`row mb-3 teams-card ${index % 2 === 0 ? 'card-left' : 'card-right'}`}>
          {index % 2 === 0 ?
            <div className='col-md-4'>
              <img
                src={member.image}
                alt={member.name}
                className="img-fluid team-image"
              />
            </div>
            : null}
          <div className="col-md-8">
            <div className="team-info">
              <h5 className="team-title">{member.name}</h5>
              <p className="team-role">{member.role}</p>
              <p className="team-description">{member.description}</p>
              <p className="team-contact">
                Téléphone : <span className='team-contact-hover'>{member.phone}</span><br />
                Email : <span className='team-contact-hover'>{member.email}</span>
              </p>
            </div>
          </div>
          {index % 2 !== 0 ?
            <div className='col-md-4'>
              <img
                src={member.image}
                alt={member.name}
                className="img-fluid team-image"
              />
            </div>
            : null}
        </div>
      ))}
    </section>
  );
};

export default TeamMembers;
